// import './index.css'

const NoMatch = () => {
  return (
<section class="hero is-danger is-fullheight">
  <div class="hero-body">
    <p class="title">
      404 {" "}
    </p>
    <p class="subtitle">
      No Page found here....
    </p>
  </div>
</section>
  );
};

export default NoMatch